import React,{useState,useEffect} from 'react'
import { useNavigate, useParams,Link } from "react-router-dom";
import { Services } from '../../api/Services';
import { ToastContainer, toast } from 'react-toastify';
import { IoReturnDownBack } from "react-icons/io5";
const selectedCompany = localStorage.getItem("selected-company")

const CreateInventory = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setIsLoading] = useState(false);
    const [product, setProduct] = useState({});
    const [currency,setCurrency]=useState('')
    const [quantity,setQuantity]=useState('')
    const [unitCost,setUnitCost]=useState('')
    const [unitSale,setUnitSale]=useState('')
    const [minimumStock,setMinimumStock]=useState('')
    const [exchangeRate,setExchangeRate]=useState('')
    const [exchangeRateDate,setExchangeRateDate]=useState('')
    const [freight,setFreight]=useState('')
    const [customDuties,setCustomDuties]=useState('')
    const [expiryDate,setExpiryDate]=useState('')
    const [expiryDateAlert,setExpiryDateAlert]=useState('')
  
    const token = localStorage.getItem("token");
    useEffect(() => {
      if (!token) {
        navigate("/");
      }
    }, []);

    const getProductListDetailFunction = async (id) => {
        setIsLoading(true);
        const result = await Services.getProductListDetail(id);
        console.log(result);
        if (result?.status === 200) {
          setProduct(result?.data?.data?.products_to_list);
        } else {
          toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setIsLoading(false);
      };
      useEffect(() => {
        getProductListDetailFunction(id);
      }, []);
    const addInventory = async ()=> {
        if(product==""){
            toast.error("Product missing", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
    //    else if(businesLine==''){
    //     toast.error("please enter businesLine", {
    //         autoClose: 2000,
    //         pauseOnHover: true,
    //         draggable: true,
    //         position: toast.POSITION.TOP_CENTER,
    //       });
    //     }
        else {
            const formData = new FormData();
            if(id){
                formData.append("product_listed_id",id)
            }
            if(quantity){
                formData.append("quantity",quantity)
            }
            if(unitCost){
                formData.append("unit_cost",unitCost)
            }
            if(unitSale){
                formData.append("sale_price",unitSale)
            }
            if(minimumStock){
                formData.append("minimum_stock",minimumStock)
            }
            if(product?.product_type){
                formData.append("product_type",product?.product_type)
            }

            if(currency){
                formData.append("currency",currency)
            }
            if(exchangeRate){
                formData.append("exchange_rate",exchangeRate)
            }
            if(exchangeRateDate){
                formData.append("exchange_rate_date",exchangeRateDate)
            }
            if(freight){
                formData.append("freight",freight)
            }
            if(customDuties){
                formData.append("custom_duties",customDuties)
            }
            if(expiryDate){
                formData.append("expiry_date",expiryDate)
            }
            if(expiryDateAlert){
                formData.append("expiry_date_alert",expiryDateAlert)
            }

             if(product & product?.custom_hs_code){
                formData.append("custom_hs_code",product?.custom_hs_code)
            }

            if(selectedCompany){
                formData.append("company_id",selectedCompany)
            }
            
            setIsLoading(true);
            const result = await Services.createInventory(formData)
            console.log(result)
            if (result?.status === 200) {
           
            toast.success("Data added sucessfuly", {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              setTimeout(()=>{
                navigate('/inventories')
              },1500)
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    
    }

 
    return (
        <div className='create-company dark-theme py-3'>
       
        <Link to='/products-list' className="back">
            <IoReturnDownBack  /> Go to Listed products   </Link>
            <ToastContainer />
        {loading && <div className="loader-wrapper">
            <div className="loader"></div>
        </div>}
    <div className="container-fluid">
        <div className="row">
           
            <div className="col-12 col-md-12">
            <div className="create-company">
        <div className="form-wrapper">
             <h2 className="title-small text-center">Add product to inventory </h2>
         
             <div className="row">
                <div className="col-12 col-md-4">
                <div className="form-group">
               <label htmlFor="">Opening stock</label>
               
               <input type='text' value={quantity} className='input' onChange={(e)=>setQuantity(e.target.value)} />
                  
               
            
               </div>
                </div>
                <div className="col-12 col-md-4">
                <div className="form-group">
               <label htmlFor="">Unit cost</label>
               
               <input type='text' value={unitCost} className='input' onChange={(e)=>setUnitCost(e.target.value)} />
                  
               
            
               </div>
                    </div>
                    <div className="col-12 col-md-4">
                <div className="form-group">
               <label htmlFor="">Unit Sale Price</label>
               
               <input type='text' value={unitSale} className='input' onChange={(e)=>setUnitSale(e.target.value)} />
                  
               
            
               </div>
                    </div>
                    <div className="col-12 col-md-4">
                    <div className="form-group">
               <label htmlFor="">Minimum Stock</label>
               
               <input type='text' value={minimumStock} className='input' onChange={(e)=>setMinimumStock(e.target.value)} />
                  
               
            
               </div>
                    </div>
            
              
             
             </div>
             {(product?.product_type == 'import and stock' || product?.product_type == 'import and trade' ) &&   <div className="row">
                <div className="col-12 col-md-6">
                <div className="form-group">
               <label htmlFor="">Currency</label>
               
               <input type='text' value={currency} className='input' onChange={(e)=>setCurrency(e.target.value)} />
                  
               
            
               </div>
                </div>
                <div className="col-12 col-md-6">
                <div className="form-group">
               <label htmlFor="">Exchange rate</label>
               
               <input type='text' value={exchangeRate} className='input' onChange={(e)=>setExchangeRate(e.target.value)} />
                  
               
            
               </div>
                </div>
                <div className="col-12 col-md-6">
                <div className="form-group">
               <label htmlFor="">Exchange rate Date</label>
               
               <input type='date' value={exchangeRateDate} className='input' onChange={(e)=>setExchangeRateDate(e.target.value)} />
                  
               
            
               </div>
                </div>
                <div className="col-12 col-md-6">
                <div className="form-group">
               <label htmlFor="">Freight</label>
               
               <input type='text' value={freight} className='input' onChange={(e)=>setFreight(e.target.value)} />
                  
               
            
               </div>
                </div>
                <div className="col-12 col-md-6">
                <div className="form-group">
               <label htmlFor="">Custom duties</label>
               
               <input type='text' value={customDuties} className='input' onChange={(e)=>setCustomDuties(e.target.value)} />
                  
               
            
               </div>
                </div>
            
             
            
              
             </div>}
             
             {product?.product_expiray_date  && <div className="row">
                <div className="col-12 col-md-6">
                    <div className="form-group">
               <label htmlFor="">Expiry Date</label>
               
               <input type='date' value={expiryDate} className='input' onChange={(e)=>setExpiryDate(e.target.value)} />
                  
               
            
               </div>
                    </div>
                    <div className="col-12 col-md-6">
                    <div className="form-group">
               <label htmlFor="">Expiry Date Alert</label>
               
               <input type='date' value={expiryDateAlert} className='input' onChange={(e)=>setExpiryDateAlert(e.target.value)} />
                  
               
            
               </div>
                    </div>
             </div>
             }
         
         <div className="d-flex justify-content-center gap-3 mt-3">
             <Link className='button me-3' to='/inventories' >Cancel</Link>
                <button className='button' onClick={()=>addInventory()}>Create now</button>
            </div>
        </div>
     </div>
            </div>
        </div>
    </div>
    
        </div>
      )
}

export default CreateInventory