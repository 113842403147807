import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link } from 'react-router-dom';
import { IoReturnDownBack } from "react-icons/io5";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ProductBar from '../ProductBar';
import Select from 'react-select';
import Origins from '../../helpers/Helpers'
const AddProuctToList = () => {
    
    const navigate = useNavigate()
     const [modal,setModal]=useState(false)
    const [product,setProduct]=useState({})
    const [loading,setIsLoading]=useState(false)
    const [productId,setProductId]=useState('')
    const [products,setProducts] =useState([])
    const [modelId,setModelId]=useState('')
   
    const [brandId,setBrandId]=useState('')
    const [brandName,setBrandName]=useState('')
    const [brandOrigin,setBrandOrigin]=useState('')
    const [brands,setBrands] =useState([])
    const [catId,setCatId]=useState('')
    const [description,setDescription]=useState('')
    const [categoryId,setCategoryId]=useState('')
    const [unitOfMeasure,setUnitOfMeasure]=useState('')
    const [productType,setProductType]=useState('')
    const [productMarkete,setProductMarkete]=useState('')
    const [origin,setOrigin]=useState('')
    const [warrenty,setWarrenty]=useState('')
    const [warrentyDuration,setWarrentyDuration]=useState('')
    const [isExpiry,setIsExpiry]=useState('')
    const [expiryAlertDate,setExpiryAlertDate] = useState('')
    const [productExpirayDate,setProductExpirayDate]=useState('')
    const [productCostingMethod,setProductCostingMethod]=useState('')
    const [picture,setPicture]=useState('')
    const [technicalSheet,setTechnicalSheet]=useState('')
    const [openingStock,setOpeningStock]=useState('')
    const [costOfPurchase,setCostOfPurchase]=useState('')
    const [vendorName,setVendorName]=useState('')
    const [minimumStock,setMinimumStock]=useState('')
    const [customHSCode,setCustomHSCode]=useState('')
    const [partsAvailibility,setPartsAvailibility]=useState('')
   //create product states
   const [categories,setCategories] =useState([])
    const [name,setName]=useState('')
    const [category,setCategory]=useState('')
    const [image,setImage]=useState(null)
    const [descriptionc,setDescriptionc]=useState('')
    
    const [unitOfMeasurec,setUnitOfMeasurec]=useState('')
 
    const handleOriginChange = (selectedOption) => {
        setOrigin(selectedOption);
      };
      const originOptions = Origins?.map((origin) => ({
        value: origin,
        label: origin,
      }));

    const productOptions = products?.map((prod) => ({
        value: prod?.id,
        label: prod?.name,
      }));

      const handleProductChange = (selectedOption) => {
        setProductId(selectedOption);
      };
     


    useEffect(() => {
        // Start with an empty description
        let newDescription = '';
      
        // Append the latest values conditionally
        if (brandId) {
            newDescription += `${brandName} - ${brandOrigin} <br>`;
          }
        if (productId && product) {
          newDescription += `${product.name}<br>`;
        }
        if (productId && product) {
            newDescription +=`${product.description} <br>`;
          }
        if (modelId) {
            newDescription +=`Model No. ${modelId} <br>`;
          }
      
        
      
        // if (warrenty) {
        //   newDescription += `Warranty: ${warrenty} <br>`;
        // }
        if (warrenty == 'Yes' && warrentyDuration) {
            newDescription += `Warranty Remarks: ${warrentyDuration}  <br>`;
          }
      
        if (partsAvailibility == "Yes" || partsAvailibility == "On demand" ) {
          newDescription += `Parts Availability: ${partsAvailibility}`;
        }
      
        // Update the description with the new values
        setDescription(newDescription.trim()); // .trim() removes any extra spaces
      }, [productId, brandId, warrenty, partsAvailibility, product,warrentyDuration,modelId]);
      
           

//handle uplpad photo
const handleUploadPicture = (e)=>{
    const file = e.target.files[0]
    setPicture(file)
}

//handle sheet upload
const handleUploadSheet = (e)=>{
    const file = e.target.files[0]
    setTechnicalSheet(file)
}
const handlePhoto = (event) => {
    const selectedFile = event.target.files[0];
    setImage(selectedFile);
  };
   
const token = localStorage.getItem('token')
const handleTextEditor = (content, delta, source, editor) => {

    setDescription(content);
};
const handleTextEditorc = (content, delta, source, editor) => {

    setDescriptionc(content);
};
const handleRemarks = (content, delta, source, editor) => {

    setWarrentyDuration(content);
};

useEffect(()=>{
if(!token){
    navigate('/')
}
},[token])



const handleBrandChange = (e) => {
    const selectedBrand = JSON.parse(e.target.value);
    setBrandId(selectedBrand.id);
    setBrandName(selectedBrand.name);
    setBrandOrigin(selectedBrand.origin)
  };

//product detail function
  const getProductDetailFunction = async (id) => {
    setBrandName('')
    setIsLoading(true);
    const result = await Services.getProductDetail(id);
    console.log(result);
    if (result?.status === 200) {
      setProduct(result?.data?.data?.product);
      setCatId(result?.data?.data?.product?.category_id);
      
    //   setDescription(result?.data?.data?.product?.description)
      setCategoryId(result?.data?.data?.product?.category_id)
      setUnitOfMeasure(result?.data?.data?.product?.unit_of_measure)
    } else {
      toast.error(result?.data?.message, {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getProductDetailFunction(productId.value);
  }, [productId.value]);
//get categories list
  
const getCategoriesList = async ()=> {
            
    setIsLoading(true);
    const result = await Services.getCategories()
    
    if (result?.status === 200) {
    
   setCategories(result?.data?.data?.categories)
   console.log(result?.data?.data?.categories)
    }
    else {
        toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
    }
    setIsLoading(false);
}

useEffect(()=>{
    getCategoriesList()
},[])
  //handle add product modal form
  const addProduct = async ()=> {
    if(name==""){
        toast.error("please enter name", {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
    }
  
    else {
        const formData = new FormData();
        if(name){
            formData.append("name",name)
        }
        if(category){
            formData.append('category_id',category)
        }
        
      
        if(image){
            formData.append('image',image)
        }
        if(descriptionc){
            formData.append('description',descriptionc)
        }
        if(unitOfMeasurec){
            formData.append('unit_of_measure',unitOfMeasurec)
        }
     
      
  
     
        setIsLoading(true);
        const result = await Services.createProduct(formData)
        console.log(result)
        if (result?.status === 200) {
       
        toast.success("Product added sucessfuly", {
            autoClose: 1000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
          getCategoriesList()
          getProductList()
          setName('')
          setUnitOfMeasurec('')
          setCategory('')
          setModal(false)
          setDescriptionc('')
          
        }
        else {
            toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
        setIsLoading(false);
    }
}
//handle add product function

 
    const addProductFunction = async (ispublish)=> {
        if(productId==""){
            toast.error("please select product id", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
       
      
        if(brandId==""){
            toast.error("please select brand id", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
      
        else {
            const formData = new FormData();
          
            if(productId){
                formData.append("product_id",productId?.value)
            }
            if(brandId){
                formData.append('brand_id',brandId)
            }
            if(ispublish) {
                formData.append('is_publish',(ispublish == '1' || ispublish == '2') ? 1 : 0)
            }
          
            if(modelId){
                formData.append('model_id',modelId)
            }
            if(description){
                formData.append('description',description)
            }
          
            if(categoryId){
                formData.append('category_id',categoryId)
            }
            if(unitOfMeasure){
                formData.append('unit_of_measure',unitOfMeasure)
            }

            if(productType){
                formData.append('product_type',productType)
            }
            if(productMarkete){
                formData.append('product_markete',productMarkete)
            }
          
            if(origin){
                formData.append('origin',origin?.value)
            }
            if(warrenty){
                formData.append('warrenty',warrenty)
            }


            if(warrentyDuration){
                formData.append('warrenty_duration',warrentyDuration)
            }
            if(productExpirayDate){
                formData.append('product_expiray_date',productExpirayDate)
            }
            if(expiryAlertDate){
                formData.append('expiry_alert_date',expiryAlertDate)
            }
           
          
            if(productCostingMethod){
                formData.append('product_costing_method',productCostingMethod)
            }
            if(picture){
                formData.append('picture',picture)
            }

            if(technicalSheet){
                formData.append('technical_sheet',technicalSheet)
            }
            // if(openingStock){
            //     formData.append('opening_stock',openingStock)
            // }
          
            // if(costOfPurchase){
            //     formData.append('cost_of_purchase',costOfPurchase)
            // }
            // if(vendorName){
            //     formData.append('vendor_name',vendorName)
            // }

            // if(minimumStock){
            //     formData.append('minimum_stock',minimumStock)
            // }
            if(customHSCode){
                formData.append('custom_hs_code',customHSCode)
            }
            if(partsAvailibility){
                formData.append('parts_availability',partsAvailibility)
            }
            
         
            setIsLoading(true);
            const result = await Services.addProductToListAPi(formData)
            console.log(result)
            if (result?.status === 200) {
           
            toast.success("Product added sucessfuly", {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              setBrandId('')
              setProductId('')
              setModelId('')
              if(ispublish == 0){
                navigate('/draft-products-list')
              }
              else if (ispublish == 2) {
                navigate('/products-list')
              }
              else {
                setBrandId('')
                setProductId('')
                setModelId('')
              }
           
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    }
    

    //get Products list
  
        const getProductList = async ()=> {
            
                setIsLoading(true);
                const result = await Services.getProducts()
                
                if (result?.status === 200) {
                
               setProducts(result?.data?.data?.products)
               
                }
                else {
                    toast.error(result?.data?.message, {
                        autoClose: 2000,
                        pauseOnHover: true,
                        draggable: true,
                        position: toast.POSITION.TOP_CENTER,
                      });
                }
                setIsLoading(false);
            }

            useEffect(()=>{
                getProductList()
            },[])
    ///Brands functions and api calls


    const getBrandsList = async (catid)=> {
       
            setBrands([])
        setIsLoading(true);
        const result = await Services.getCategoryBrands(catid)
        
        if (result?.status === 200) {
        
       setBrands(result?.data?.data?.brands)
       
        }
        else {
            toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
        setIsLoading(false);
    }

    useEffect(()=>{
        getBrandsList(catId)
    },[catId])
    ///Models functions and api calls


  
 


  return (
    <div className='create-company dark-theme py-3'>
    <ProductBar />
<Link to='/products' className="back">
    <IoReturnDownBack  /> Go to products
</Link>


{modal && <div className='modal-fixed'>
        <div className="form-modal">
            <div className="icon">
            <i class="fa-regular fa-circle-xmark" onClick={()=>setModal(false)}></i>
            </div>
            <p className="title">
                Design product
            </p>
          
            <div className="row">
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Name</label>
                <input type="text" className='input' value={name}  onChange={(e)=>setName(e.target.value)}  />
                </div>
            </div>
           
            
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Photo</label>
                <input type="file" accept=".jpg, .jpeg, .png, .gif" className='input'   onChange={handlePhoto}   />
                </div>
            </div>
            <div className="col-12 col-md-6">
               
                <div className="form-group">
                <label htmlFor="">Category </label>
                <div className="categories-wrapper">
                <select className='input' value={category} onChange={(e)=>setCategory(e.target.value)}>
                <option value="">Please select</option>
                    {categories?.map((cat)=> <option key={cat?.id} value={cat?.id}>{cat?.name}</option>)}
                </select>
             
                </div>
                
                </div>
               
            </div>
           
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Unit of measure </label>
                <select className='input' value={unitOfMeasurec} onChange={(e)=>setUnitOfMeasurec(e.target.value)}>
                <option value="">Please select</option>
                    <option value="KG">KG</option>
                    <option value="PKT">PKT</option>
                    <option value="PC">PC</option>
                    <option value="PC's">PC's</option>
                    <option value="NOS">NOS</option>
                    <option value="FT">FT</option>
                    <option value="MTR">MTR</option>
                    <option value="SQFT">SQFT</option>
                    <option value="SQMT">SQMT</option>
                    <option value="g">g</option>
                    <option value="Gm">Gm</option>
                    <option value="MM">MM</option>
                    <option value="Inch">Inch</option>
                    
                </select>
                </div>
            </div>
            <div className="col-12">
              
                <div className="form-group">
               <label htmlFor="">Form specifications  </label>
               
               <ReactQuill  value={descriptionc} onChange={handleTextEditorc} />
            
               </div>
            </div>
        </div>
        
        
         <div className="text-center m-3 ">
            <button className='button'onClick={()=>addProduct()} >Add product now</button>
        </div>
</div>
</div>
}
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}


<div className="container-fluid">
    <div className="row">
      
        <div className="col-12 col-md-12">
        <div className="create-company">
    <div className="form-wrapper">
         <h2 className="title-small text-center">Product Profile for inventory </h2>
     
         <div className="row">
          
           
            
          
            <div className="col-12 col-md-6">
               
                <div className="form-group">
                <label htmlFor="">Select product</label>
                 <div className="categories-wrapper">
                
                   
                   
                     
                       <Select
        id="product-select"
        value={productId}
        onChange={handleProductChange}
        options={productOptions}
        isClearable
        placeholder="Search and select a product"
      />
                  
                   <button className="button" onClick={()=>setModal((pre)=>!pre)}>Add new</button>
                 </div>
                
             
                </div>
                
                </div>
                <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Select Brand </label>
               
               <select value={brandId} onChange={(e) => handleBrandChange(e)}>
  <option value="">{brandName ? brandName : 'Select Brand'}</option>

  {brands?.map((brand,iddd) => (
    <option key={iddd} value={JSON.stringify(brand)}>
      {brand.name}
    </option>
  ))}
 
</select>
            
               </div>
               
               </div>
               <div className="col-12 col-md-6">
               
               <div className="form-group ">
               <label htmlFor="">Enter Model No </label>
               <div className="categories-wrapper">
                <input type="text" className="input" value={modelId} onChange={(e)=>setModelId(e.target.value)} />
               
             </div>
               </div>
               
               </div>
               <div className="col-12 col-md-6">
               <div className="form-group">
                <label htmlFor="">Unit of measure</label>
                <select className='input' value={unitOfMeasure} onChange={(e)=>setUnitOfMeasure(e.target.value)}>
                <option value="">Please select</option>
                    <option value="KG">KG</option>
                    <option value="PKT">PKT</option>
                    <option value="PC">PC</option>
                    <option value="PC's">PC's</option>
                    <option value="NOS">NOS</option>
                    <option value="FT">FT</option>
                    <option value="MTR">MTR</option>
                    <option value="SQFT">SQFT</option>
                    <option value="SQMT">SQMT</option>
                    <option value="g">g</option>
                    <option value="Gm">Gm</option>
                    <option value="MM">MM</option>
                    <option value="Inch">Inch</option>
                    
                </select>
                </div>
               
               
               </div>
               <div className="col-12">
               {product && <div className="form-group">
               <label htmlFor="">Technical specifications  </label>
               
               <ReactQuill  value={description} onChange={handleTextEditor} />
            
               </div>}
               
               
               </div>
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Product Type </label>
               
               <select value={productType} onChange={(e)=>setProductType(e.target.value)}>
                  
               <option value="">Please select</option>
             
                   <option value="local stock">local stock</option>
                   <option value="local trade">local trade</option>
                   <option value="import and stock">import and stock</option>
                   <option value="import and trade">import and trade</option>
               </select>
            
               </div>
               
               </div>
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Product Markete Type </label>
               
               <select value={productMarkete} onChange={(e)=>setProductMarkete(e.target.value)}>
                  
               <option value="">Please select</option>
                   <option value="Local">Local</option>
                   <option value="Import">Import</option>
                   <option value="Import & Local">Import & Local</option>
                   <option value="Manufacturing">Manufacturing</option>
               </select>
            
               </div>
               
               </div>
               <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Origion of product</label>
                <Select
        id="origin-select1"
        value={origin}
        onChange={handleOriginChange}
        options={originOptions}
        isClearable
        placeholder="Search and select an origin"
      />
                </div>
            </div>
            <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Warrenty </label>
               
               <select value={warrenty} onChange={(e)=>setWarrenty(e.target.value)}>
                  
               <option value="">Please select</option>
                   <option value="Yes">Yes</option>
                   <option value="No">No</option>
                   
               </select>
            
               </div>
               
               </div>
               {warrenty == 'Yes' && <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Warrenty remarks </label>
               
               
               <ReactQuill  value={warrentyDuration} onChange={handleRemarks} />
               
            
               </div>
               
               </div>}

               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Expiry Date </label>
               
               <select value={isExpiry} onChange={(e)=>setIsExpiry(e.target.value)}>
                  
               <option value="">Please select</option>
                   <option value="Yes">Yes</option>
                   <option value="No">No</option>
                   
               </select>
            
               </div>
               
               </div>
               {isExpiry == "Yes" && <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Product Expiry Date </label>
               
               <input type='date' value={productExpirayDate} className='input' onChange={(e)=>setProductExpirayDate(e.target.value)} />
                  
               
            
               </div>
               
               </div>}
               {isExpiry == "Yes" &&
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Product Expiry Alert Date </label>
               
               <input type='date' value={expiryAlertDate} className='input' onChange={(e)=>setExpiryAlertDate(e.target.value)} />
                  
               
            
               </div>
               
               </div>
               }
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Product Costing Method </label>
               
               <select value={productCostingMethod} onChange={(e)=>setProductCostingMethod(e.target.value)}>
                  
               <option value="">Please select</option>
               <option value="FIFO">FIFO</option>
                   <option value="LIFO">LIFO</option>
                   
                   <option value="Average">Average</option>
                   
               </select>
            
               </div>
               
               </div>
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Upload Picture </label>
               
               <input type='file'  className='input' onChange={handleUploadPicture} />
                  
               
            
               </div>
               
               </div>
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Upload Technical Sheet </label>
               
               <input type='file'  className='input' onChange={handleUploadSheet} />
                  
               
            
               </div>
               
               </div>
               {/* <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Opening Stock </label>
               
               <input type='text'  value={openingStock} className='input' onChange={(e)=>setOpeningStock(e.target.value)} />
                  
               
            
               </div>
               
               </div> */}
               {/* <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Cost Of Purchase </label>
               
               <input type='text'  value={costOfPurchase} className='input' onChange={(e)=>setCostOfPurchase(e.target.value)} />
                  
               
            
               </div>
               
               </div> */}
               {/* <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Vendor Name </label>
               
               <input type='text'  value={vendorName} className='input' onChange={(e)=>setVendorName(e.target.value)} />
                  
               
            
               </div>
               
               </div> */}
               {/* <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Minimun Stock </label>
               
               <input type='text'  value={minimumStock} className='input' onChange={(e)=>setMinimumStock(e.target.value)} />
                  
               
            
               </div>
               
               </div> */}
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Custom HS Code </label>
               
               <input type='text'  value={customHSCode} className='input' onChange={(e)=>setCustomHSCode(e.target.value)} />
                  
               
            
               </div>
               
               </div>
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Parts availibilty </label>
               
               <select value={partsAvailibility} onChange={(e)=>setPartsAvailibility(e.target.value)}>
                  
               <option value="">Please select</option>
                   <option value="Yes">Yes</option>
                   <option value="No">No</option>
                   <option value="On demand">On demand</option>
                   
               </select>
            
               </div>
               
               </div>
            </div>
          
        </div>
       
        
         <div className="text-center m-3 d-flex justify-content-center gap-4 ">
         <Link className='button me-3' to='/products-list' >Cancel</Link>
            <button className='button'onClick={()=>addProductFunction('1')} >Save & New </button>
            <button className='button'onClick={()=>addProductFunction('0')} >Save As Draft </button>
            <button className='button'onClick={()=>addProductFunction('2')} >Save & Exit </button>
        </div>
    </div>
 </div>
        </div>
    </div>
</div>

    
  )
}

export default AddProuctToList